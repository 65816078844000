/* ==========================================================================
    #HEADER
    ========================================================================== */

// Variables
/////////////////////////////

#{$header} {
  padding:20px 0;

  border-bottom:10px solid #006bb7;
  background-color:$color-white;

  @include media('<desktop') {
    padding:15px 0;
    height:90px;
    border-width:6px;
  }

  &__container {
    max-width:1220px;
    position: relative;
  }

  &__logo {
    display: block;
 
      &--desktop {
        width:213px;
        position: relative;
        top: -3px;

        @include media('<desktop') {
          display:none;
        }

      }

      &--mobile {
        width:107px;

        @include media('>=desktop') {
          display:none;
        }

      }

  
  }

  &__btn-become-member {
    position:absolute;
    bottom:0;
    right:0;

    @include media('<desktop') {
      display:none;
    }

  }
  

  .nav-primary {
    position: absolute;
    bottom:10px;
    left:290px;

    @include media('<desktop') {
      display:none;
    }

    ul {
      @include clearfix();
    }

    li {
      float:left;

      &:not(:last-child) {
        margin-right:30px;
      }
    }

    a {
      @include gibsonSemiBold();
      color: #006bb7;
      font-size: 27px;
      line-height: 1;

      &:hover {
        color:#7c7f7f;
      }

    }

  }

  .nav-secondary {
    position: absolute;
    top:0;
    right:0;

    @include media('<desktop') {
      display:none;
    }
    
    ul {
      @include clearfix();
    }

    li {
      float:left;

      &:not(:last-child) {
        margin-right:30px;
      }

    }

    a {
      color: #7c7f7f;
      font-family: $GibsonRegular;
      font-size: 16px;
      line-height: 1;

      &:hover {
        color: #006bb7;
      }

    }

    /* exception */
    li.menu-item-alt-color {

      a {
        color: #006bb7;
        
        &:hover {
          color: #7c7f7f;
        }

      }

    }

  }


  &__mobile-don {
    padding:15px;
    position: absolute;
    top:-4px;
    right:65px;

    @include media('>desktop') {
      display:none;
    }

    .fa {
      color:#006bb7;
      font-size:30px;
    }
  }

  &__btn-mobile {
    position: absolute;
    top:3px;
    right:5px;

    @include media('>desktop') {
      display:none;
    }

  }

}

/* ==========================================================================
    #HEADER-MODIFIER
    ========================================================================== */





.l-menu-mobile {
  position: fixed;
  z-index:1000;
  top:83px;
  left:0;
  width:100%;
  height:calc(100% - 83px);
  overflow-y: auto;
  background: #f8f8f8;
  opacity:0;
  transition:opacity 0.3s ease-in, visibility 0s linear;
  text-align:center;
  padding-bottom:40px;

  visibility: hidden;

  @include media('>desktop') {
    display:none;
  }

  body.has-nav-mobile-active & {
    opacity:1;
    visibility:visible;
  }

  ul {
    @include center-block();
    max-width:480px;
    text-align:center;
    margin-top:20px;
    padding:0 20px;

    li {
      margin-bottom:24px;

      a {
        display: inline-block;

      }

      &.level-1 {

        a {
          @include gibsonSemiBold();
          color:#006bb7;
          font-size:24px;
          line-height:1;
        }

      }

      &.level-2 {

        a {
          @include gibsonRegular();
          font-size:18px;
          line-height:1;
        }

        &.alt {
          color:#006bb7;
        }

      }

    }

  /* exception */
  li.menu-item-alt-color {
    
    a {
      color: #006bb7;
      
      &:hover {
        color: #7c7f7f;
      }

    }
    
  }

}

.social {
  display:inline-block;

  a {
    font-size:20px;
    
    margin-right:16px;

    &:last-child {
      margin-right:0;
    }

  }

}

}