/* ==========================================================================
    #HERO
    ========================================================================== */

// Variables
/////////////////////////////

#{$hero-home} {
  @include component-border-bottom();
  
  position: relative;
 
  color: $color-white;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size:cover;

  @include media('>tablet') {
    padding:70px 0;
  }

  @include media('<=desktop') {
    padding:30px 0;
  }

  &--has-overlay {

    &:after {
      content:"";
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index:10;
      background: rgba(#1d4672,0.4);
    }

  }

  &__container {
    position: relative;
    z-index:100;

    
    min-height: 300px;
    height: 50vh;
    

  }

  &__block {

      position:absolute;
      bottom:0;
      left:0;
      z-index:200;
      padding:0 20px;
  }

  &__logo {
    margin-bottom:30px;
  }

  &__title {
    @include gibsonSemiBold("title");
    margin-bottom:15px;

    font-size: 80px;
    line-height:1;

    @include media('<=desktop') {
      margin-bottom:10px;
      font-size:55px;
    }

    @include media('<=tablet') {
      font-size:40px;
    }

  }

  &__subtitle {
    @include gibsonSemiBold("title");
    margin-bottom:30px;

    font-size: 17px;
    line-height: 1.2;
    text-transform: uppercase;

    @include media('<=tablet') {
      margin-bottom:15px;
      font-size:16px;
    }

    &:before,
    &:after{
      content:"";
      margin:0 10px;
      display: inline-block;
      width:20px;
      height:2px;

      position: relative;
      top:-5px;

      background-color: rgba(#fff,0.5);
    }

  }

  &__content {
    font-size: 28px;
    font-weight: 400;
    line-height: 1.2;

    @include media('<=desktop') {
      font-size:16px;
    }

    p {

      &:not(:last-child) {
        margin-bottom:14px;
      }

    }

  }

  &__btn {
    margin-top:30px;
    min-width:240px;
  }


}

/* ==========================================================================
    #HERO-MODIFIER
    ========================================================================== */

/* ==========================================================================
    #HERO-STYLE
    ========================================================================== */


