/* ==========================================================================
    #page-404
    ========================================================================== */

// Variables
/////////////////////////////

#{$page-404} {

	.page-header {
		display: none; 
	}
}




