/* ==========================================================================
    #TEXT-TRUNCATE
    ========================================================================== */

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;

  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
}


