

// 0.npm-node-modules ( Vendors )
///////////////////////////////////////////

// core
@import "11.vendors/_include-media";
@import "11.vendors/css-hamburgers/hamburgers";


// 1.settings
///////////////////////////////////////////
@import '1.settings/variables';
@import '1.settings/selectors';

// 2.tools - functions
///////////////////////////////////////////
@import '2.tools/functions/map';

// 2.tools - mixins
///////////////////////////////////////////

// General
@import '2.tools/mixins/animation';
@import '2.tools/mixins/transition';

// Helpers
@import '2.tools/mixins/helpers/pseudo';
@import '2.tools/mixins/helpers/center-block';
@import '2.tools/mixins/helpers/clearfix';
@import '2.tools/mixins/helpers/input-placeholder';
@import '2.tools/mixins/helpers/responsive-ratio';
@import '2.tools/mixins/helpers/text-truncate';
@import '2.tools/mixins/helpers/triangle';
@import '2.tools/mixins/helpers/embed';
@import '2.tools/mixins/helpers/mobile';

// Elements
@import '2.tools/mixins/elements/btn';
@import '2.tools/mixins/elements/links';
@import '2.tools/mixins/elements/icons';
@import '2.tools/mixins/elements/heading';
@import '2.tools/mixins/elements/text';

// 3.base
///////////////////////////////////////////
@import '3.base/reset';
@import '3.base/fonts';
@import '3.base/typography';
@import '3.base/global';
@import '3.base/icons';

// 4.elements
///////////////////////////////////////////
@import '4.elements/btn';
@import '4.elements/components-color';

// 5.components
///////////////////////////////////////////
@import '5.components/wysiwyg';

@import '5.components/partners';
@import '5.components/text';
@import '5.components/news';
@import '5.components/hero';
@import '5.components/hero-home';
@import '5.components/features';

// 6.objets
///////////////////////////////////////////
@import '6.objets/container';
@import '6.objets/grid';

// 7.layout
///////////////////////////////////////////
@import '7.layout/wrapper';
@import '7.layout/header';
@import '7.layout/nav';
@import '7.layout/content';
@import '7.layout/footer';

// 8.pages
///////////////////////////////////////////
@import '8.pages/404';

// 9.themes
///////////////////////////////////////////
@import '9.themes/light';
@import '9.themes/dark';

// 10.utils
///////////////////////////////////////////
@import '10.utils/mq-status';
@import '10.utils/mq-visibility';

// 11.vendors
///////////////////////////////////////////

// Shame
///////////////////////////////////////////
@import 'shame';
@import 'gform';

.fa-mr {
  margin-right: 8px;
}






