/* ==========================================================================
    #PARTNERS
    ========================================================================== */

// Variables
/////////////////////////////

#{$partners} {
  text-align:center;

  @include media('>tablet') {
    padding:70px 0 55px 0;
  }

  @include media('<=tablet') {
    padding:30px 0;
  }

  &__title {
    @include gibsonSemiBold("title");
    margin-bottom:40px;

    color:#fff;

    font-size:32px;
    line-height:1;

    @include media('<=tablet') {
      font-size:28px;
    }

    @include media('<=phone') {
      font-size:24px;
    }

  }

  &__list {

    @include media('>tablet') {
      display:flex;
      justify-content:center;
      align-items:center;
    }

    li {
      margin-right:40px;

      @include media('<=tablet') {
        margin-bottom:30px;
        margin-right:0;
      }

      &:last-child {
        margin-right:0;
        margin-bottom:0;
      }

    }

  }

  img {
    @include center-block();
  }

}

/* ==========================================================================
    #PARTNERS-MODIFIER
    ========================================================================== */

/* ==========================================================================
    #PARTNERS-STYLE
    ========================================================================== */


