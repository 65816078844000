/* ==========================================================================
    #GRID
    Framework : LOST
    Doc : http://lostgrid.org/docs.html
    Example : http://lostgrid.org/lostgrid-example.html
    ========================================================================== */

// grid
/////////////////////////////
.o-grid {}

// columns
/////////////////////////////
@for $i from 2 through 12 {

  .o-grid-#{$i} {

      & > .o-grid-col {
          lost-column: 1/#{$i};
      }

  }
}


.row {
  lost-utility: clearfix;
}

.quarter {
  lost-column: 1/4 0;
  @include media('<tablet') { float: none; width: 100%; }
}

.third {
  lost-column: 1/3 0;
  @include media('<tablet') { float: none; width: 100%; }
}

.half {
  lost-column: 1/2 0;
  @include media('<tablet') { float: none; width: 100%; }
}

.two-third {
  lost-column: 2/3 0;
  @include media('<tablet') { float: none; width: 100%; }
}

.three-quarter {
  lost-column: 3/4 0;
  @include media('<tablet') { float: none; width: 100%; }
}