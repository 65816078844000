
.main .gform_wrapper {

    margin-top: 40px;
    margin-bottom: 40px;

    input[type="submit"],
    button,
    .button {
      @extend .btn;
      color: #fff;
      background-color: #006bb7;
      border-color: #006bb7;

      &:hover {
        color: #006bb7;
        background: none;
      } 

    }

    select,
    textarea,
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
      padding: 8px;
      background-color:#eee;
      border-radius: 0;
    }

    .gform_footer input.button, .gform_footer input[type=submit]  {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .field_description_below .gfield_description {
      padding-top: 8px;
    }

    .gfield_required,
    div.validation_error,
    .gfield_error .gfield_label,
    .validation_message,
    li.gfield_error div.ginput_complex.ginput_container label,
    li.gfield_error ul.gfield_checkbox, li.gfield_error ul.gfield_radio {
      color: #ec4399;
    }

    div.validation_error {
      padding: 0;
      border: 0;
    }

    li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
    li.gfield_error textarea  {
      border: 0;
      background-color: #f9edf3;
    }

    li.gfield.gfield_error,
    li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
      background: none;
      margin-bottom: 0;
      border: none;
      padding: 0px;
    }

    .gform_confirmation_message  {
      color: #006bb7;
    }
}