/* ==========================================================================
    #btn-reset
    ========================================================================== */

@mixin btn-reset() {
  user-select: none;

  -webkit-appearance: none;
  -webkit-box-align: center;
}

/* ==========================================================================
    #btn-reset
    ========================================================================== */

@mixin btn-base-primary() {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;

  display: inline-block;

  color: $color-white;
  border-radius: 0;
  background-color: $color-purple;

  &:hover {
    color: $color-white;
    background-color: $color-dark01;
  }
}

/* ==========================================================================
    #btn-size
    ========================================================================== */

@mixin btn-small() {
  padding: 6px 10px;

  font-size: 10px;

  i {
    margin-left: 4px;

    top: 2px;

    font-size: 12px;
  }
}

@mixin btn-regular() {
  padding: 12px 16px;

  font-size: 12px;

  i {
    margin-left: 10px;

    top: 1px;

    font-size: 13px;
  }
}

@mixin btn-big() {
  padding: 20px 30px;

  font-size: 14px;

  i {
    margin-left: 10px;

    top: 1px;

    font-size: 13px;
  }
}
