/* ==========================================================================
    #CLEARFIX
    ========================================================================== */

// Ref : http://www.cssmojo.com/the-very-latest-clearfix-reloaded/

@mixin clearfix() {
  &:after {
    display: block !important;
    clear: both !important;

    content: '' !important;
  }
}
