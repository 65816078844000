/* ==========================================================================
    #heading-1
    ========================================================================== */
@mixin heading-1() {
}

/* ==========================================================================
    #heading-2
    ========================================================================== */
@mixin heading-2() {
}

/* ==========================================================================
    #heading-3
    ========================================================================== */
@mixin heading-3() {
}

/* TO DO 
@mixin title($align : 'left') {

    @if $align == 'center' {
        text-align: center;
    }

}
*/
