/* ==========================================================================
    #WYSIWYG
    ========================================================================== */

// Variables
/////////////////////////////

#{$wysiwyg} {
  color: #777777;
  font-size: 18px;
  line-height: 1.5;

  @include media('<=tablet') {
    font-size:16px;
  }

  // lobotomized-owls
  // https://alistapart.com/article/axiomatic-css-and-lobotomized-owls
  /////////////////////////////
  & > * + *,
  & .row > div * + * {
    margin-top: 25px;

    @include media('<=tablet') {
      margin-top: 15px;
    }

  }

  & > ul + * {
    margin-top: 40px;

    @include media('<=tablet') {
      margin-top: 25px;
    }

  }

  & > *:first-child {
    margin-top:0;
  }

  p {
    line-height: 1.5;
  }

  // empty tag
  ///////////////////////////// 
  p:empty {
    display: none
  }

  a:not(.btn) {
    color: #0387c5;

    &:hover {
      color: lighten(#0387c5, 10%);
    }
  }

  // heading
  ///////////////////////////// 
  h1,h2,h3,h4,h5,h6 {
    @include gibsonSemiBold("title");
    color:#0387c5;

    line-height:1.2;
    font-weight:600;
    text-transform: uppercase;

    & + * {
      margin-top:20px;
    }

  }

  h1 {
    font-size: 30px;
    text-align: center;

    @include media('<=tablet') {
      font-size:24px;
    }

  }

  h2 {
    font-size: 22.5px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    color: #888;

    &:before,
    &:after{
      content:"";
      margin:0 10px;
      display: inline-block;
      width:20px;
      height:2px;

      position: relative;
      top:-7px;

      background-color: rgba(#000,0.2);
    }

    @include media('<=tablet') {
      font-size:20px;
    }

  }

  h3 {
    font-size: 20px;

    @include media('<=tablet') {
      font-size:18px;
    }

  }

  h4 {
    font-size: 24px;

    @include media('<=tablet') {
      font-size:19px;
    }

  }

  h5 {
    font-size: 22px;

    @include media('<=tablet') {
      font-size:18px;
    }

  }

  h6 {
    font-size: 22px;

    @include media('<=tablet') {
      font-size:18px;
    }

  }

  // list
  ///////////////////////////// 
  ol,
  ul {
    padding-left:1.4em;

    li {
      margin-bottom:6px;
    }

    ul {
      margin-top: .5em;
      margin-bottom: 1.5em;
    }


  }

  ol {
    list-style-type:decimal;
    

  }

  ul {
    list-style-type: disc;
  }

  // images
  ///////////////////////////// 
  img:not(.alignnone) {
    @include center-block();
  }

  // figure
  ///////////////////////////// 
  figure {
    @include center-block();
  }

  // table
  ///////////////////////////// 
  table {
    table-layout:fixed;
    width:100%;
    font-size:18px;

    td {
      width:50%;
      padding-right:30px;
      padding-bottom:20px;
    }

  }
  
}

/* ==========================================================================
    #WYSIWYG-MODIFIER
    ========================================================================== */

/* ==========================================================================
    #WYSIWYG-STYLE
    ========================================================================== */