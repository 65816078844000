/* ==========================================================================
    #icon-css
    ========================================================================== */
@mixin icon-css($content: false) {
  font-family: 'icomoon' !important;
  line-height: 1;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;

  display: block;

  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @if $content {
    content: '#{$content}';
  }
}




