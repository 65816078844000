/* ==========================================================================
    #FEATURES
    ========================================================================== */

// Variables
/////////////////////////////

#{$features} {
  @include component-border-bottom();

  position: relative;
  text-align:center;

    @include media('>tablet') {
      padding:80px 0;
    }

    @include media('<=tablet') {
      padding:40px 0;
    }

 
  &__container {}

  &__title {
    @include gibsonSemiBold("title");
    margin-bottom:60px;

    color:#0387c5;

    font-size: 46px;

    @include media('<=tablet') {
        margin-bottom:30px;
        font-size:24px;
    }

  }

  &__list {

    @include media('>tablet') {
      display:flex;
      flex-wrap:wrap;
      justify-content:center;
    }

    color:#888;

    li {

      @include media('>tablet') {
        margin-bottom: 60px;
        margin-right: 4%;
        width: calc(33.33% - 8%);

        &:last-child {
          margin-right: 0;
        }
      }

      @include media('<=tablet') {
          margin-bottom:30px;
      }

    }

    &-image {
      @include center-block();
      margin-bottom:30px;
      width: 143px;
    } 

    &-title {
      @include gibsonSemiBold("title");
      line-height: 1.25; 
      margin-bottom: 10px;

      color: #0387c5;

      font-size: 23px;
    }

    &-subtitle {
      @include gibsonSemiBold();
      letter-spacing: 0.075em;
      margin-bottom: 30px;

      font-size: 15px;
      line-height: 1.2;
      text-transform: uppercase;

      &:before,
      &:after{
        content:"";
        margin:0 10px;
        display: inline-block;
        width:20px;
        height:2px;

        position: relative;
        top:-4px;

        background-color: rgba(#888,0.5);
      }

    }

    &-content {
      font-size: 18px;
      line-height: 1.4;

      p {

        &:not(last-child) {
          margin-bottom:14px;
        }

      }


    }    

  }



}


#{$wysiwyg} {

  #{$features} {
  
    &__list {

      text-align: center;
      padding-left: 0;
      list-style: none;

      li {

          width: 100%;
          margin-right: 0;

      }

      &-subtitle {
        margin-top: 0 !important;
      }  

    }
  }

}

/* ==========================================================================
    #FEATURES-MODIFIER
    ========================================================================== */

/* ==========================================================================
    #FEATURES-STYLE
    ========================================================================== */


