/* ==========================================================================
    #TEXT
    ========================================================================== */

// Variables
/////////////////////////////

#{$text} {
  @include component-border-bottom();
  background-color:$color-white;

  &:nth-child(odd) {
    background-color: #f6f6f6;
  } 

  @include media('>tablet') {
    padding:80px 0;
  }

  @include media('<=tablet') {
    padding:30px 0;
  }

  &__subtitle {
    margin-bottom:50px;

    color: #0387c5;
    font-size: 30px;
    line-height: 1.4;

    @include media('<=tablet') {
        margin-bottom:25px;
        font-size:18px;
    }

  }

}

/* ==========================================================================
    #TEXT-MODIFIER
    ========================================================================== */

/* ==========================================================================
    #TEXT-STYLE
    ========================================================================== */

