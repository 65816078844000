/* ==========================================================================
    #CENTER-BLOCK
    ========================================================================== */

@mixin center-block() {
  margin-right: auto;
  margin-left: auto;

  display: block;
}
