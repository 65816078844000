/* ==========================================================================
    #BTN
    ========================================================================== */

// Variables
/////////////////////////////

#{$btn} {
  @include gibsonSemiBold();
  padding:13px 30px;
  display: inline-block;
  min-width:220px;

  color: $color-white;
  border-radius:100px;
  border: 2px solid rgba($color-white, 0.5);
  
  font-size: 14px;
  letter-spacing:1px;
  line-height:1;
  text-transform: uppercase;
  text-align:center;

  transition: all 0.2s ease-in;

  &:hover {
    background-color:$color-white;
    color:$color-black;
  }

  /* context parent ( color ) */
  .components-color--gray & {
    background-color:transparent;
    border-color: rgba(100,100,100, 0.5);
    color:#666;

    &:hover {
      background-color:#666;
      color:$color-white;
    }

  }

  /* context parent ( color ) */
  .components-color--purple & {

    &:hover {
      color:$color-purple;
    }

  }
  
  .components-color--green & {

    &:hover {
      color:$color-green;
    }

  }
  
}


.btn--large {
  padding:15px 26px;

  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn--blue {
  color: #006bb7;
  border-color:rgba(#006bb7,0.5);

  &:hover {
    background-color:#006bb7;
    color:$color-white;
  }

}

.btn--white {
  color: $color-white;
  border: 2px solid rgba($color-white, 0.5);

  &:hover {
    background-color:$color-white;
    color:$color-black;
  }

}



