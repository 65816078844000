/* ==========================================================================
    #TRANSITION
    ========================================================================== */

@mixin transition-fade-in( $opacity : .8, $speed : $transition-speed-medium, $easing : linear ) {
  transition: opacity $speed $easing;

  opacity: 0;

  &:hover {
    opacity: $opacity;
  }
}

@mixin transition-fade-out( $opacity : .8, $speed : $transition-speed-medium, $easing : linear ) {
  transition: opacity $speed $easing;

  opacity: $opacity;

  &:hover {
    opacity: 0;
  }
}


@mixin component-border-bottom() {
  border-bottom:7px solid #fff;
}




