/* ==========================================================================
    #COMPONENTS-COLORS
    ========================================================================== */

// Variables
/////////////////////////////

#{$components-color} {
  
  &,
  &--gray {
    color:#777;
    background-color:$color-gray;
    background: linear-gradient(to right, #edeeee 0%, #d7d7d7 100%); 

    
    &:not(.c-hero--has-overlay) {
        
      .c-news__title,
      .c-hero__title {
        color:#0387c5;
      }

      .c-hero__content {
        color: #666;
      }
      .c-hero__subtitle {

        color: #777;

        &:before,
        &:after {
          background-color: rgba(0, 0, 0, 0.15);
        }
      }

      .c-hero__btn {
        color: #0387c5;
        border-color: rgba(#0387c5, 0.5);

        &:hover {
          background-color: #0387c5;
          color: $color-white;
        }
      }
    }


  }

  &--dark-gray {
    color:#fff;
    background-color: #b0b0b0;
    background: linear-gradient(to right, #afafaf 0%, #c0c0c0 100%); 
  }

  &--purple {
    color:#fff;
    background-color: $color-purple;
    background: linear-gradient(to right, #af19f6 0%, #c919f6 100%); 
  }

  &--green {
    color:#fff;
    background-color: $color-green;
    background: linear-gradient(to right, #76dcc6 0%, #10c0a6 100%); 
  }

}





