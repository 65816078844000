/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 18px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 2px;
    background-color: #006bb7;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -8px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -8px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -8px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -8px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 8px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 16px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -16px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 8px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 16px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -16px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 8px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 8px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1px; }
  .hamburger--slider .hamburger-inner::before {
    top: 8px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 16px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.7142857143px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -16px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 8px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 16px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.7142857143px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -16px, 0) rotate(90deg); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 8px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/* ==========================================================================
    #CONFIG
    ========================================================================== */
/* ==========================================================================
    #FOLDER
    ========================================================================== */
/* ==========================================================================
    #TYPOGRAPHY
    ========================================================================== */
/* ==========================================================================
    #COLORS
    ========================================================================== */
/* ==========================================================================
    #FORM
    ========================================================================== */
/* ==========================================================================
    #MEDIA-QUERIES
    ========================================================================== */
/* ==========================================================================
    #CONTAINER
    ========================================================================== */
/* ==========================================================================
    #Z-INDEX
    ========================================================================== */
/* ==========================================================================
    #TRANISTION
    ========================================================================== */
/* ==========================================================================
    #SELECTORS
========================================================================== */
/* ==========================================================================
    #ANIMATION
    ========================================================================== */
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: both; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: both; }

@keyframes spin-loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.spin-loader {
  animation-name: spin-loader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both; }

/* ==========================================================================
    #TRANSITION
    ========================================================================== */
/* ==========================================================================
    #CENTER-BLOCK
    ========================================================================== */
/* ==========================================================================
    #CLEARFIX
    ========================================================================== */
/* ==========================================================================
    #INPUT-PLACEHOLDER
    ========================================================================== */
/*
input,  
textarea {  
    @include input-placeholder {
        color: $grey;
    }
}
*/
/* ==========================================================================
    #RESPONSIVE-RATIO
    ========================================================================== */
/*

Example : http://codepen.io/paallaire/pen/zNVwdR?editors=1100

div { @include responsive-ratio(16,9); }

*/
/* ==========================================================================
    #TEXT-TRUNCATE
    ========================================================================== */
/* TO DO */
/* ==========================================================================
    #btn-reset
    ========================================================================== */
/* ==========================================================================
    #btn-reset
    ========================================================================== */
/* ==========================================================================
    #btn-size
    ========================================================================== */
/* ==========================================================================
    #link-default
    ========================================================================== */
/* ==========================================================================
    #icon-css
    ========================================================================== */
/* ==========================================================================
    #heading-1
    ========================================================================== */
/* ==========================================================================
    #heading-2
    ========================================================================== */
/* ==========================================================================
    #heading-3
    ========================================================================== */
/* TO DO 
@mixin title($align : 'left') {

    @if $align == 'center' {
        text-align: center;
    }

}
*/
/* ==========================================================================
    #text-1
    ========================================================================== */
/* ==========================================================================
    #text-2
    ========================================================================== */
/* ==========================================================================
    #text-3
    ========================================================================== */
/* ==========================================================================
    #BOX-SIZING
    ========================================================================== */
*,
*:after,
*:before {
  box-sizing: border-box;
  background-repeat: no-repeat;
  /* Set `background-repeat: no-repeat` to all elements and pseudo elements */ }

/* ==========================================================================
    #HTML
    ========================================================================== */
html {
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased; }

/* ==========================================================================
    #BODY
    ========================================================================== */
body {
  line-height: 1; }

/* ==========================================================================
    #RESET-TAGS
    ========================================================================== */
a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var,
figure,
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline; }

ul,
ol {
  list-style: none; }

a {
  color: inherit;
  outline: none;
  text-decoration: none;
  background-color: transparent;
  /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects;
  /* Remove gaps in links underline in iOS 8+ and Safari 8+ */ }
  a:active, a:hover {
    outline-width: 0;
    /* Remove the outline when hovering in all browsers */ }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none;
  /* Remove border when inside `a` element in IE 8/9/10 */
  image-rendering: -webkit-optimize-contrast; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

b,
strong {
  font-weight: bolder;
  /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */ }

/* ==========================================================================
    #FROM
    ========================================================================== */
input {
  border-radius: 0; }

/* Apply cursor pointer to button elements */
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
  cursor: pointer; }

/* Replace pointer cursor in disabled elements */
[disabled] {
  cursor: default; }

[type="number"] {
  width: auto;
  /* Firefox 36+ */ }

[type="search"] {
  -webkit-appearance: textfield;
  /* Safari 8+ */ }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* Safari 8 */ }

textarea {
  overflow: auto;
  /* Internet Explorer 11+ */
  resize: vertical;
  /* Specify textarea resizability */ }

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* Specify font inheritance of form elements */ }

optgroup {
  font-weight: bold;
  /* Restore the font weight unset by the previous rule. */ }

button {
  overflow: visible;
  /* Address `overflow` set to `hidden` in IE 8/9/10/11 */ }

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0; }

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS */ }

button,
select {
  text-transform: none;
  /* Firefox 40+, Internet Explorer 11- */ }

/* Remove the default button styling in all browsers */
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit; }

/* Style select like a standard input */
select {
  -moz-appearance: none;
  /* Firefox 36+ */
  -webkit-appearance: none;
  /* Chrome 41+ */ }

select::-ms-expand {
  display: none;
  /* Internet Explorer 11+ */ }

select::-ms-value {
  color: currentColor;
  /* Internet Explorer 11+ */ }

legend {
  border: 0;
  /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit;
  /* Correct the color inheritance from `fieldset` elements in IE */
  display: table;
  /* Correct the text wrapping in Edge and IE */
  max-width: 100%;
  /* Correct the text wrapping in Edge and IE */
  white-space: normal;
  /* Correct the text wrapping in Edge and IE */ }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit;
  /* Change font properties to `inherit` in Chrome and Safari */ }

[type="search"] {
  -webkit-appearance: textfield;
  /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px;
  /* Correct the outline style in Safari */ }

/* ==========================================================================
    #SELECTION
    ========================================================================== */
/* Specify text selection background color and omit drop shadow */
::-moz-selection {
  background-color: #b3d4fc;
  /* Required when declaring ::selection */
  color: #000;
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* Required when declaring ::selection */
  color: #000;
  text-shadow: none; }

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 16, 2017 */
@font-face {
  font-family: 'gibsonregular';
  src: url("../fonts/gibson-webfont.woff2") format("woff2"), url("../fonts/gibson-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gibsonsemibold';
  src: url("../fonts/gibson-semibold-webfont.woff2") format("woff2"), url("../fonts/gibson-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gibsonsemibold_italic';
  src: url("../fonts/gibson-semibold-italic-webfont.woff2") format("woff2"), url("../fonts/gibson-semibold-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gibsonbold';
  src: url("../fonts/gibson-bold-webfont.woff2") format("woff2"), url("../fonts/gibson-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gibsonbold_italic';
  src: url("../fonts/gibson-bold-italic-webfont.woff2") format("woff2"), url("../fonts/gibson-bold-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* ==========================================================================
    #HTML-BODY
    ========================================================================== */
body,
html {
  min-width: 320px;
  height: 100%; }

/* ==========================================================================
    #HTML
    ========================================================================== */
html {
  overflow-y: scroll;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  html.is-fixed {
    position: fixed;
    overflow: hidden; }

/* ==========================================================================
    #BODY
    ========================================================================== */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 10px;
  line-height: 1.4;
  word-wrap: break-word;
  word-break: normal;
  color: #777;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  transition: all 0.15s; }

/* ==========================================================================
    #BTN
    ========================================================================== */
.btn, .main .gform_wrapper input[type="submit"],
.main .gform_wrapper button,
.main .gform_wrapper .button {
  font-family: "gibsonsemibold", serif;
  font-weight: normal;
  letter-spacing: 0.02em;
  padding: 13px 30px;
  display: inline-block;
  min-width: 220px;
  color: #fff;
  border-radius: 100px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s ease-in;
  /* context parent ( color ) */
  /* context parent ( color ) */ }
  .btn:hover, .main .gform_wrapper input[type="submit"]:hover,
  .main .gform_wrapper button:hover,
  .main .gform_wrapper .button:hover {
    background-color: #fff;
    color: #000; }
  .components-color--gray .btn, .components-color--gray .main .gform_wrapper input[type="submit"], .main .gform_wrapper .components-color--gray input[type="submit"], .components-color--gray
  .main .gform_wrapper button,
  .main .gform_wrapper .components-color--gray button, .components-color--gray
  .main .gform_wrapper .button,
  .main .gform_wrapper .components-color--gray .button {
    background-color: transparent;
    border-color: rgba(100, 100, 100, 0.5);
    color: #666; }
    .components-color--gray .btn:hover, .components-color--gray .main .gform_wrapper input[type="submit"]:hover, .main .gform_wrapper .components-color--gray input[type="submit"]:hover, .components-color--gray
    .main .gform_wrapper button:hover,
    .main .gform_wrapper .components-color--gray button:hover, .components-color--gray
    .main .gform_wrapper .button:hover,
    .main .gform_wrapper .components-color--gray .button:hover {
      background-color: #666;
      color: #fff; }
  .components-color--purple .btn:hover, .components-color--purple .main .gform_wrapper input[type="submit"]:hover, .main .gform_wrapper .components-color--purple input[type="submit"]:hover, .components-color--purple
  .main .gform_wrapper button:hover,
  .main .gform_wrapper .components-color--purple button:hover, .components-color--purple
  .main .gform_wrapper .button:hover,
  .main .gform_wrapper .components-color--purple .button:hover {
    color: #b111f7; }
  .components-color--green .btn:hover, .components-color--green .main .gform_wrapper input[type="submit"]:hover, .main .gform_wrapper .components-color--green input[type="submit"]:hover, .components-color--green
  .main .gform_wrapper button:hover,
  .main .gform_wrapper .components-color--green button:hover, .components-color--green
  .main .gform_wrapper .button:hover,
  .main .gform_wrapper .components-color--green .button:hover {
    color: #74dcc6; }

.btn--large {
  padding: 15px 26px;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px; }

.btn--blue {
  color: #006bb7;
  border-color: rgba(0, 107, 183, 0.5); }
  .btn--blue:hover {
    background-color: #006bb7;
    color: #fff; }

.btn--white {
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.5); }
  .btn--white:hover {
    background-color: #fff;
    color: #000; }

/* ==========================================================================
    #COMPONENTS-COLORS
    ========================================================================== */
.components-color, .components-color--gray {
  color: #777;
  background-color: #b1b1b1;
  background: linear-gradient(to right, #edeeee 0%, #d7d7d7 100%); }
  .components-color:not(.c-hero--has-overlay) .c-news__title,
  .components-color:not(.c-hero--has-overlay) .c-hero__title, .components-color--gray:not(.c-hero--has-overlay) .c-news__title,
  .components-color--gray:not(.c-hero--has-overlay) .c-hero__title {
    color: #0387c5; }
  .components-color:not(.c-hero--has-overlay) .c-hero__content, .components-color--gray:not(.c-hero--has-overlay) .c-hero__content {
    color: #666; }
  .components-color:not(.c-hero--has-overlay) .c-hero__subtitle, .components-color--gray:not(.c-hero--has-overlay) .c-hero__subtitle {
    color: #777; }
    .components-color:not(.c-hero--has-overlay) .c-hero__subtitle:before, .components-color:not(.c-hero--has-overlay) .c-hero__subtitle:after, .components-color--gray:not(.c-hero--has-overlay) .c-hero__subtitle:before, .components-color--gray:not(.c-hero--has-overlay) .c-hero__subtitle:after {
      background-color: rgba(0, 0, 0, 0.15); }
  .components-color:not(.c-hero--has-overlay) .c-hero__btn, .components-color--gray:not(.c-hero--has-overlay) .c-hero__btn {
    color: #0387c5;
    border-color: rgba(3, 135, 197, 0.5); }
    .components-color:not(.c-hero--has-overlay) .c-hero__btn:hover, .components-color--gray:not(.c-hero--has-overlay) .c-hero__btn:hover {
      background-color: #0387c5;
      color: #fff; }

.components-color--dark-gray {
  color: #fff;
  background-color: #b0b0b0;
  background: linear-gradient(to right, #afafaf 0%, #c0c0c0 100%); }

.components-color--purple {
  color: #fff;
  background-color: #b111f7;
  background: linear-gradient(to right, #af19f6 0%, #c919f6 100%); }

.components-color--green {
  color: #fff;
  background-color: #74dcc6;
  background: linear-gradient(to right, #76dcc6 0%, #10c0a6 100%); }

/* ==========================================================================
    #WYSIWYG
    ========================================================================== */
.c-wysiwyg {
  color: #777777;
  font-size: 18px;
  line-height: 1.5; }
  @media (max-width: 768px) {
    .c-wysiwyg {
      font-size: 16px; } }
  .c-wysiwyg > * + *,
  .c-wysiwyg .row > div * + * {
    margin-top: 25px; }
    @media (max-width: 768px) {
      .c-wysiwyg > * + *,
      .c-wysiwyg .row > div * + * {
        margin-top: 15px; } }
  .c-wysiwyg > ul + * {
    margin-top: 40px; }
    @media (max-width: 768px) {
      .c-wysiwyg > ul + * {
        margin-top: 25px; } }
  .c-wysiwyg > *:first-child {
    margin-top: 0; }
  .c-wysiwyg p {
    line-height: 1.5; }
  .c-wysiwyg p:empty {
    display: none; }
  .c-wysiwyg a:not(.btn):not(.main .gform_wrapper input[type="submit"]):not(
  .main .gform_wrapper button):not(
  .main .gform_wrapper .button) {
    color: #0387c5; }
    .c-wysiwyg a:not(.btn):not(.main .gform_wrapper input[type="submit"]):not(
    .main .gform_wrapper button):not(
    .main .gform_wrapper .button):hover {
      color: #04a9f7; }
  .c-wysiwyg h1, .c-wysiwyg h2, .c-wysiwyg h3, .c-wysiwyg h4, .c-wysiwyg h5, .c-wysiwyg h6 {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.04em;
    color: #0387c5;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase; }
    .c-wysiwyg h1 + *, .c-wysiwyg h2 + *, .c-wysiwyg h3 + *, .c-wysiwyg h4 + *, .c-wysiwyg h5 + *, .c-wysiwyg h6 + * {
      margin-top: 20px; }
  .c-wysiwyg h1 {
    font-size: 30px;
    text-align: center; }
    @media (max-width: 768px) {
      .c-wysiwyg h1 {
        font-size: 24px; } }
  .c-wysiwyg h2 {
    font-size: 22.5px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    color: #888; }
    .c-wysiwyg h2:before, .c-wysiwyg h2:after {
      content: "";
      margin: 0 10px;
      display: inline-block;
      width: 20px;
      height: 2px;
      position: relative;
      top: -7px;
      background-color: rgba(0, 0, 0, 0.2); }
    @media (max-width: 768px) {
      .c-wysiwyg h2 {
        font-size: 20px; } }
  .c-wysiwyg h3 {
    font-size: 20px; }
    @media (max-width: 768px) {
      .c-wysiwyg h3 {
        font-size: 18px; } }
  .c-wysiwyg h4 {
    font-size: 24px; }
    @media (max-width: 768px) {
      .c-wysiwyg h4 {
        font-size: 19px; } }
  .c-wysiwyg h5 {
    font-size: 22px; }
    @media (max-width: 768px) {
      .c-wysiwyg h5 {
        font-size: 18px; } }
  .c-wysiwyg h6 {
    font-size: 22px; }
    @media (max-width: 768px) {
      .c-wysiwyg h6 {
        font-size: 18px; } }
  .c-wysiwyg ol,
  .c-wysiwyg ul {
    padding-left: 1.4em; }
    .c-wysiwyg ol li,
    .c-wysiwyg ul li {
      margin-bottom: 6px; }
    .c-wysiwyg ol ul,
    .c-wysiwyg ul ul {
      margin-top: .5em;
      margin-bottom: 1.5em; }
  .c-wysiwyg ol {
    list-style-type: decimal; }
  .c-wysiwyg ul {
    list-style-type: disc; }
  .c-wysiwyg img:not(.alignnone) {
    margin-right: auto;
    margin-left: auto;
    display: block; }
  .c-wysiwyg figure {
    margin-right: auto;
    margin-left: auto;
    display: block; }
  .c-wysiwyg table {
    table-layout: fixed;
    width: 100%;
    font-size: 18px; }
    .c-wysiwyg table td {
      width: 50%;
      padding-right: 30px;
      padding-bottom: 20px; }

/* ==========================================================================
    #WYSIWYG-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #WYSIWYG-STYLE
    ========================================================================== */
/* ==========================================================================
    #PARTNERS
    ========================================================================== */
.c-partners {
  text-align: center; }
  @media (min-width: 769px) {
    .c-partners {
      padding: 70px 0 55px 0; } }
  @media (max-width: 768px) {
    .c-partners {
      padding: 30px 0; } }
  .c-partners__title {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 40px;
    color: #fff;
    font-size: 32px;
    line-height: 1; }
    @media (max-width: 768px) {
      .c-partners__title {
        font-size: 28px; } }
    @media (max-width: 520px) {
      .c-partners__title {
        font-size: 24px; } }
  @media (min-width: 769px) {
    .c-partners__list {
      display: flex;
      justify-content: center;
      align-items: center; } }
  .c-partners__list li {
    margin-right: 40px; }
    @media (max-width: 768px) {
      .c-partners__list li {
        margin-bottom: 30px;
        margin-right: 0; } }
    .c-partners__list li:last-child {
      margin-right: 0;
      margin-bottom: 0; }
  .c-partners img {
    margin-right: auto;
    margin-left: auto;
    display: block; }

/* ==========================================================================
    #PARTNERS-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #PARTNERS-STYLE
    ========================================================================== */
/* ==========================================================================
    #TEXT
    ========================================================================== */
.c-text {
  border-bottom: 7px solid #fff;
  background-color: #fff; }
  .c-text:nth-child(odd) {
    background-color: #f6f6f6; }
  @media (min-width: 769px) {
    .c-text {
      padding: 80px 0; } }
  @media (max-width: 768px) {
    .c-text {
      padding: 30px 0; } }
  .c-text__subtitle {
    margin-bottom: 50px;
    color: #0387c5;
    font-size: 30px;
    line-height: 1.4; }
    @media (max-width: 768px) {
      .c-text__subtitle {
        margin-bottom: 25px;
        font-size: 18px; } }

/* ==========================================================================
    #TEXT-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #TEXT-STYLE
    ========================================================================== */
/* ==========================================================================
    #NEWS
    ========================================================================== */
.c-news {
  border-bottom: 7px solid #fff;
  text-align: center; }
  @media (min-width: 769px) {
    .c-news {
      padding: 110px 0; } }
  @media (max-width: 768px) {
    .c-news {
      padding: 30px 0; } }
  .c-news__title {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 60px;
    font-size: 46px; }
    @media (max-width: 768px) {
      .c-news__title {
        margin-bottom: 30px;
        font-size: 28px; } }
    @media (max-width: 520px) {
      .c-news__title {
        font-size: 20px; } }
  .c-news__list li {
    margin-bottom: 40px; }
  .c-news__list-date {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.02em;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px; }
    .c-news__list-date:before, .c-news__list-date:after {
      content: "";
      margin: 0 10px;
      display: inline-block;
      width: 20px;
      height: 2px;
      position: relative;
      top: -3px;
      background-color: rgba(255, 255, 255, 0.5); }
  .c-news__list-content {
    margin-top: 20px;
    font-size: 20px;
    line-height: 1.4; }
  @media (max-width: 768px) {
    .c-news__title + .c-news__list {
      margin-top: 60px; } }
  @media (max-width: 520px) {
    .c-news__title + .c-news__list {
      margin-top: 30px; } }

/* ==========================================================================
    #NEWS-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #NEWS-STYLE
    ========================================================================== */
/* ==========================================================================
    #HERO
    ========================================================================== */
.c-hero {
  border-bottom: 7px solid #fff;
  position: relative;
  text-align: center;
  color: #fff;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (min-width: 769px) {
    .c-hero {
      padding: 60px 0; } }
  @media (max-width: 768px) {
    .c-hero {
      padding: 30px 0; } }
  .c-hero--has-overlay:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(29, 70, 114, 0.8); }
  .c-hero__container {
    position: relative;
    z-index: 100; }
  .c-hero__logo {
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-bottom: 30px; }
  .c-hero__title {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
    font-size: 46px; }
    .c-hero__title:last-child {
      margin-bottom: 5px; }
    @media (max-width: 768px) {
      .c-hero__title {
        margin-bottom: 10px;
        font-size: 30px; }
        .c-hero__title:last-child {
          margin-bottom: 0px; } }
  .c-hero__subtitle {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 30px;
    letter-spacing: 0.075em;
    font-size: 17px;
    line-height: 1.2;
    text-transform: uppercase; }
    .c-hero__subtitle:last-child {
      margin-bottom: 10px; }
    @media (max-width: 768px) {
      .c-hero__subtitle {
        margin-bottom: 15px;
        font-size: 14px; }
        .c-hero__subtitle:last-child {
          margin-bottom: 5px; } }
    .c-hero__subtitle:before, .c-hero__subtitle:after {
      content: "";
      margin: 0 10px;
      display: inline-block;
      width: 20px;
      height: 2px;
      position: relative;
      top: -5px;
      background-color: rgba(255, 255, 255, 0.5); }
  .c-hero__content {
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-width: 760px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4; }
    @media (max-width: 768px) {
      .c-hero__content {
        font-size: 16px; } }
    .c-hero__content:first-child {
      font-family: "gibsonsemibold", serif;
      font-weight: normal;
      letter-spacing: 0.04em;
      letter-spacing: 0.015em;
      font-size: 28px; }
      @media (max-width: 768px) {
        .c-hero__content:first-child {
          font-size: 22px; } }
    .c-hero__content p:not(last-child) {
      margin-bottom: 14px; }
  .c-hero .c-hero__btn {
    margin-top: 40px;
    color: #fff;
    border: 2px solid rgba(255, 255, 255, 0.5); }
    .c-hero .c-hero__btn:hover {
      background-color: #fff;
      color: #000; }

/* ==========================================================================
    #HERO-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #HERO-STYLE
    ========================================================================== */
/* ==========================================================================
    #HERO
    ========================================================================== */
.c-hero-home {
  border-bottom: 7px solid #fff;
  position: relative;
  color: #fff;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (min-width: 769px) {
    .c-hero-home {
      padding: 70px 0; } }
  @media (max-width: 1120px) {
    .c-hero-home {
      padding: 30px 0; } }
  .c-hero-home--has-overlay:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(29, 70, 114, 0.4); }
  .c-hero-home__container {
    position: relative;
    z-index: 100;
    min-height: 300px;
    height: 50vh; }
  .c-hero-home__block {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 200;
    padding: 0 20px; }
  .c-hero-home__logo {
    margin-bottom: 30px; }
  .c-hero-home__title {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 15px;
    font-size: 80px;
    line-height: 1; }
    @media (max-width: 1120px) {
      .c-hero-home__title {
        margin-bottom: 10px;
        font-size: 55px; } }
    @media (max-width: 768px) {
      .c-hero-home__title {
        font-size: 40px; } }
  .c-hero-home__subtitle {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 30px;
    font-size: 17px;
    line-height: 1.2;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .c-hero-home__subtitle {
        margin-bottom: 15px;
        font-size: 16px; } }
    .c-hero-home__subtitle:before, .c-hero-home__subtitle:after {
      content: "";
      margin: 0 10px;
      display: inline-block;
      width: 20px;
      height: 2px;
      position: relative;
      top: -5px;
      background-color: rgba(255, 255, 255, 0.5); }
  .c-hero-home__content {
    font-size: 28px;
    font-weight: 400;
    line-height: 1.2; }
    @media (max-width: 1120px) {
      .c-hero-home__content {
        font-size: 16px; } }
    .c-hero-home__content p:not(:last-child) {
      margin-bottom: 14px; }
  .c-hero-home__btn {
    margin-top: 30px;
    min-width: 240px; }

/* ==========================================================================
    #HERO-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #HERO-STYLE
    ========================================================================== */
/* ==========================================================================
    #FEATURES
    ========================================================================== */
.c-features {
  border-bottom: 7px solid #fff;
  position: relative;
  text-align: center; }
  @media (min-width: 769px) {
    .c-features {
      padding: 80px 0; } }
  @media (max-width: 768px) {
    .c-features {
      padding: 40px 0; } }
  .c-features__title {
    font-family: "gibsonsemibold", serif;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin-bottom: 60px;
    color: #0387c5;
    font-size: 46px; }
    @media (max-width: 768px) {
      .c-features__title {
        margin-bottom: 30px;
        font-size: 24px; } }
  .c-features__list {
    color: #888; }
    @media (min-width: 769px) {
      .c-features__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; } }
    @media (min-width: 769px) {
      .c-features__list li {
        margin-bottom: 60px;
        margin-right: 4%;
        width: calc(33.33% - 8%); }
        .c-features__list li:last-child {
          margin-right: 0; } }
    @media (max-width: 768px) {
      .c-features__list li {
        margin-bottom: 30px; } }
    .c-features__list-image {
      margin-right: auto;
      margin-left: auto;
      display: block;
      margin-bottom: 30px;
      width: 143px; }
    .c-features__list-title {
      font-family: "gibsonsemibold", serif;
      font-weight: normal;
      letter-spacing: 0.04em;
      line-height: 1.25;
      margin-bottom: 10px;
      color: #0387c5;
      font-size: 23px; }
    .c-features__list-subtitle {
      font-family: "gibsonsemibold", serif;
      font-weight: normal;
      letter-spacing: 0.02em;
      letter-spacing: 0.075em;
      margin-bottom: 30px;
      font-size: 15px;
      line-height: 1.2;
      text-transform: uppercase; }
      .c-features__list-subtitle:before, .c-features__list-subtitle:after {
        content: "";
        margin: 0 10px;
        display: inline-block;
        width: 20px;
        height: 2px;
        position: relative;
        top: -4px;
        background-color: rgba(136, 136, 136, 0.5); }
    .c-features__list-content {
      font-size: 18px;
      line-height: 1.4; }
      .c-features__list-content p:not(last-child) {
        margin-bottom: 14px; }

.c-wysiwyg .c-features__list {
  text-align: center;
  padding-left: 0;
  list-style: none; }
  .c-wysiwyg .c-features__list li {
    width: 100%;
    margin-right: 0; }
  .c-wysiwyg .c-features__list-subtitle {
    margin-top: 0 !important; }

/* ==========================================================================
    #FEATURES-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #FEATURES-STYLE
    ========================================================================== */
/* ==========================================================================
    #CONTAINER
    ========================================================================== */
.o-container-mini {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 320px; }

.o-container-tiny {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 620px; }

.o-container-small {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 840px; }

.o-container-medium {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 900px; }

.o-container-large {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 960px; }

.o-container-big {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 1120px; }

.o-container-max {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 1440px; }

.o-container-full {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 100%; }

.o-container-mini-padding {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 320px; }

.o-container-tiny-padding {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 620px; }

.o-container-small-padding {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 840px; }

.o-container-medium-padding {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 900px; }

.o-container-large-padding {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 960px; }

.o-container-big-padding {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1120px; }

.o-container-max-padding {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1440px; }

.o-container-full-padding {
  margin-right: auto;
  margin-left: auto;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 100%; }

/* ==========================================================================
    #GRID
    Framework : LOST
    Doc : http://lostgrid.org/docs.html
    Example : http://lostgrid.org/lostgrid-example.html
    ========================================================================== */
.o-grid-2 > .o-grid-col {
  lost-column: 1/2; }

.o-grid-3 > .o-grid-col {
  lost-column: 1/3; }

.o-grid-4 > .o-grid-col {
  lost-column: 1/4; }

.o-grid-5 > .o-grid-col {
  lost-column: 1/5; }

.o-grid-6 > .o-grid-col {
  lost-column: 1/6; }

.o-grid-7 > .o-grid-col {
  lost-column: 1/7; }

.o-grid-8 > .o-grid-col {
  lost-column: 1/8; }

.o-grid-9 > .o-grid-col {
  lost-column: 1/9; }

.o-grid-10 > .o-grid-col {
  lost-column: 1/10; }

.o-grid-11 > .o-grid-col {
  lost-column: 1/11; }

.o-grid-12 > .o-grid-col {
  lost-column: 1/12; }

.row {
  lost-utility: clearfix; }

.quarter {
  lost-column: 1/4 0; }
  @media (max-width: 767px) {
    .quarter {
      float: none;
      width: 100%; } }

.third {
  lost-column: 1/3 0; }
  @media (max-width: 767px) {
    .third {
      float: none;
      width: 100%; } }

.half {
  lost-column: 1/2 0; }
  @media (max-width: 767px) {
    .half {
      float: none;
      width: 100%; } }

.two-third {
  lost-column: 2/3 0; }
  @media (max-width: 767px) {
    .two-third {
      float: none;
      width: 100%; } }

.three-quarter {
  lost-column: 3/4 0; }
  @media (max-width: 767px) {
    .three-quarter {
      float: none;
      width: 100%; } }

/* ==========================================================================
    #WRAPPER
    ========================================================================== */
/* ==========================================================================
    #WRAPPER-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #HEADER
    ========================================================================== */
.l-header {
  padding: 20px 0;
  border-bottom: 10px solid #006bb7;
  background-color: #fff; }
  @media (max-width: 1119px) {
    .l-header {
      padding: 15px 0;
      height: 90px;
      border-width: 6px; } }
  .l-header__container {
    max-width: 1220px;
    position: relative; }
  .l-header__logo {
    display: block; }
    .l-header__logo--desktop {
      width: 213px;
      position: relative;
      top: -3px; }
      @media (max-width: 1119px) {
        .l-header__logo--desktop {
          display: none; } }
    .l-header__logo--mobile {
      width: 107px; }
      @media (min-width: 1120px) {
        .l-header__logo--mobile {
          display: none; } }
  .l-header__btn-become-member {
    position: absolute;
    bottom: 0;
    right: 0; }
    @media (max-width: 1119px) {
      .l-header__btn-become-member {
        display: none; } }
  .l-header .nav-primary {
    position: absolute;
    bottom: 10px;
    left: 290px; }
    @media (max-width: 1119px) {
      .l-header .nav-primary {
        display: none; } }
    .l-header .nav-primary ul:after {
      display: block !important;
      clear: both !important;
      content: '' !important; }
    .l-header .nav-primary li {
      float: left; }
      .l-header .nav-primary li:not(:last-child) {
        margin-right: 30px; }
    .l-header .nav-primary a {
      font-family: "gibsonsemibold", serif;
      font-weight: normal;
      letter-spacing: 0.02em;
      color: #006bb7;
      font-size: 27px;
      line-height: 1; }
      .l-header .nav-primary a:hover {
        color: #7c7f7f; }
  .l-header .nav-secondary {
    position: absolute;
    top: 0;
    right: 0;
    /* exception */ }
    @media (max-width: 1119px) {
      .l-header .nav-secondary {
        display: none; } }
    .l-header .nav-secondary ul:after {
      display: block !important;
      clear: both !important;
      content: '' !important; }
    .l-header .nav-secondary li {
      float: left; }
      .l-header .nav-secondary li:not(:last-child) {
        margin-right: 30px; }
    .l-header .nav-secondary a {
      color: #7c7f7f;
      font-family: "gibsonregular", serif;
      font-size: 16px;
      line-height: 1; }
      .l-header .nav-secondary a:hover {
        color: #006bb7; }
    .l-header .nav-secondary li.menu-item-alt-color a {
      color: #006bb7; }
      .l-header .nav-secondary li.menu-item-alt-color a:hover {
        color: #7c7f7f; }
  .l-header__mobile-don {
    padding: 15px;
    position: absolute;
    top: -4px;
    right: 65px; }
    @media (min-width: 1121px) {
      .l-header__mobile-don {
        display: none; } }
    .l-header__mobile-don .fa {
      color: #006bb7;
      font-size: 30px; }
  .l-header__btn-mobile {
    position: absolute;
    top: 3px;
    right: 5px; }
    @media (min-width: 1121px) {
      .l-header__btn-mobile {
        display: none; } }

/* ==========================================================================
    #HEADER-MODIFIER
    ========================================================================== */
.l-menu-mobile {
  position: fixed;
  z-index: 1000;
  top: 83px;
  left: 0;
  width: 100%;
  height: calc(100% - 83px);
  overflow-y: auto;
  background: #f8f8f8;
  opacity: 0;
  transition: opacity 0.3s ease-in, visibility 0s linear;
  text-align: center;
  padding-bottom: 40px;
  visibility: hidden; }
  @media (min-width: 1121px) {
    .l-menu-mobile {
      display: none; } }
  body.has-nav-mobile-active .l-menu-mobile {
    opacity: 1;
    visibility: visible; }
  .l-menu-mobile ul {
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-width: 480px;
    text-align: center;
    margin-top: 20px;
    padding: 0 20px;
    /* exception */ }
    .l-menu-mobile ul li {
      margin-bottom: 24px; }
      .l-menu-mobile ul li a {
        display: inline-block; }
      .l-menu-mobile ul li.level-1 a {
        font-family: "gibsonsemibold", serif;
        font-weight: normal;
        letter-spacing: 0.02em;
        color: #006bb7;
        font-size: 24px;
        line-height: 1; }
      .l-menu-mobile ul li.level-2 a {
        font-family: "gibsonregular", serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 1; }
      .l-menu-mobile ul li.level-2.alt {
        color: #006bb7; }
    .l-menu-mobile ul li.menu-item-alt-color a {
      color: #006bb7; }
      .l-menu-mobile ul li.menu-item-alt-color a:hover {
        color: #7c7f7f; }
  .l-menu-mobile .social {
    display: inline-block; }
    .l-menu-mobile .social a {
      font-size: 20px;
      margin-right: 16px; }
      .l-menu-mobile .social a:last-child {
        margin-right: 0; }

/* ==========================================================================
    #NAV
    ========================================================================== */
/* ==========================================================================
    #NAV-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #CONTENT
    ========================================================================== */
/* ==========================================================================
    #CONTENT-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #FOOTER
    ========================================================================== */
.l-footer .l-footer-information {
  padding: 40px 20px;
  background: linear-gradient(45deg, #444444 0%, #624d68 100%); }
  @media (min-width: 769px) {
    .l-footer .l-footer-information {
      padding: 40px 20px; } }
  @media (max-width: 768px) {
    .l-footer .l-footer-information {
      padding: 40px 0; } }

.l-footer .nav-footer {
  color: rgba(255, 255, 255, 0.75);
  font-family: "gibsonregular", serif;
  /* level 1 */
  /* level 2 */ }
  .l-footer .nav-footer a {
    display: block; }
    .l-footer .nav-footer a:hover {
      color: #fff; }
  @media (min-width: 769px) {
    .l-footer .nav-footer > ul > li {
      lost-column: 1/3 3 60px no-flex; } }
  .l-footer .nav-footer > ul > li {
    margin-bottom: 30px; }
    .l-footer .nav-footer > ul > li > a {
      font-size: 23px;
      margin-bottom: 20px; }
  .l-footer .nav-footer .sub-menu > li {
    margin-bottom: 10px; }
    .l-footer .nav-footer .sub-menu > li:last-child {
      margin-bottom: 0; }
  .l-footer .nav-footer a {
    font-size: 17px; }

@media (min-width: 769px) {
  .l-footer .l-footer-information .row {
    lost-flex-container: row;
    flex-wrap: wrap; } }

@media (min-width: 769px) {
  .l-footer .l-footer-sitemap {
    margin-top: 60px;
    max-width: 720px;
    width: 100%; } }

.l-footer .l-footer-contact {
  margin-left: auto; }
  @media (min-width: 769px) {
    .l-footer .l-footer-contact {
      margin-top: 60px; } }
  .l-footer .l-footer-contact .footer-logo {
    margin-bottom: 20px;
    width: 178px;
    opacity: 0.6; }

.l-footer .c-contact-info {
  color: rgba(255, 255, 255, 0.8);
  font-family: "gibsonregular", serif; }
  .l-footer .c-contact-info__title {
    margin-bottom: 18px;
    font-size: 23px; }
  .l-footer .c-contact-info__adr {
    font-size: 18px;
    line-height: 1.6; }

.l-footer .footer-copyright {
  color: rgba(255, 255, 255, 0.3);
  font-family: "gibsonregular", serif;
  font-size: 18px; }
  @media (min-width: 769px) {
    .l-footer .footer-copyright {
      margin-top: 40px; } }
  @media (max-width: 768px) {
    .l-footer .footer-copyright {
      margin-top: 20px; } }

/* ==========================================================================
    #FOOTER-MODIFIER
    ========================================================================== */
/* ==========================================================================
    #page-404
    ========================================================================== */
.error404 .page-header {
  display: none; }

/* ==========================================================================
    #LIGHT
    ========================================================================== */
/* ==========================================================================
    #DARK
    ========================================================================== */
/* ==========================================================================
    #DEBUG BREAKPOINT
    ========================================================================== */
/* ==========================================================================
    #BREAKPOINT VISIBILITY
    ========================================================================== */
@media (max-width: 519px) {
  .hide-small {
    display: none; } }

@media (max-width: 767px) {
  .hide-phone {
    display: none; } }

@media (max-width: 1119px) {
  .hide-tablet {
    display: none; } }

@media (max-width: 1439px) {
  .hide-desktop {
    display: none; } }

@media (max-width: 1439px) {
  .hide-large {
    display: none; } }

.show-small {
  display: none; }
  @media (min-width: 320px) {
    .show-small {
      display: block; } }

.show-small-inline {
  display: none; }
  @media (min-width: 320px) {
    .show-small-inline {
      display: inline; } }

.show-small-inline-block {
  display: none; }
  @media (min-width: 320px) {
    .show-small-inline-block {
      display: inline-block; } }

.show-phone {
  display: none; }
  @media (min-width: 520px) {
    .show-phone {
      display: block; } }

.show-phone-inline {
  display: none; }
  @media (min-width: 520px) {
    .show-phone-inline {
      display: inline; } }

.show-phone-inline-block {
  display: none; }
  @media (min-width: 520px) {
    .show-phone-inline-block {
      display: inline-block; } }

.show-tablet {
  display: none; }
  @media (min-width: 768px) {
    .show-tablet {
      display: block; } }

.show-tablet-inline {
  display: none; }
  @media (min-width: 768px) {
    .show-tablet-inline {
      display: inline; } }

.show-tablet-inline-block {
  display: none; }
  @media (min-width: 768px) {
    .show-tablet-inline-block {
      display: inline-block; } }

.show-desktop {
  display: none; }
  @media (min-width: 1120px) {
    .show-desktop {
      display: block; } }

.show-desktop-inline {
  display: none; }
  @media (min-width: 1120px) {
    .show-desktop-inline {
      display: inline; } }

.show-desktop-inline-block {
  display: none; }
  @media (min-width: 1120px) {
    .show-desktop-inline-block {
      display: inline-block; } }

.show-large {
  display: none; }
  @media (min-width: 1440px) {
    .show-large {
      display: block; } }

.show-large-inline {
  display: none; }
  @media (min-width: 1440px) {
    .show-large-inline {
      display: inline; } }

.show-large-inline-block {
  display: none; }
  @media (min-width: 1440px) {
    .show-large-inline-block {
      display: inline-block; } }

@media (min-height: 321px) and (max-height: 768px) {
  #breakpoints {
    height: 50%; } }

.main .gform_wrapper {
  margin-top: 40px;
  margin-bottom: 40px; }
  .main .gform_wrapper input[type="submit"],
  .main .gform_wrapper button,
  .main .gform_wrapper .button {
    color: #fff;
    background-color: #006bb7;
    border-color: #006bb7; }
    .main .gform_wrapper input[type="submit"]:hover,
    .main .gform_wrapper button:hover,
    .main .gform_wrapper .button:hover {
      color: #006bb7;
      background: none; }
  .main .gform_wrapper select,
  .main .gform_wrapper textarea,
  .main .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    padding: 8px;
    background-color: #eee;
    border-radius: 0; }
  .main .gform_wrapper .gform_footer input.button, .main .gform_wrapper .gform_footer input[type=submit] {
    margin-left: auto;
    margin-right: auto;
    display: block; }
  .main .gform_wrapper .field_description_below .gfield_description {
    padding-top: 8px; }
  .main .gform_wrapper .gfield_required,
  .main .gform_wrapper div.validation_error,
  .main .gform_wrapper .gfield_error .gfield_label,
  .main .gform_wrapper .validation_message,
  .main .gform_wrapper li.gfield_error div.ginput_complex.ginput_container label,
  .main .gform_wrapper li.gfield_error ul.gfield_checkbox, .main .gform_wrapper li.gfield_error ul.gfield_radio {
    color: #ec4399; }
  .main .gform_wrapper div.validation_error {
    padding: 0;
    border: 0; }
  .main .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  .main .gform_wrapper li.gfield_error textarea {
    border: 0;
    background-color: #f9edf3; }
  .main .gform_wrapper li.gfield.gfield_error,
  .main .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background: none;
    margin-bottom: 0;
    border: none;
    padding: 0px; }
  .main .gform_wrapper .gform_confirmation_message {
    color: #006bb7; }

.fa-mr {
  margin-right: 8px; }
