/* ==========================================================================
    #CONTAINER
    ========================================================================== */

// Variables
/////////////////////////////
$container : '.o-container';

// container with no paddding 
/////////////////////////////
@each $key, $value in $map-containers {

  #{$container}-#{$key} {
    @include center-block();

    max-width: $value;

  }

}

// container with padding
/////////////////////////////
@each $key, $value in $map-containers {

  #{$container}-#{$key}-padding {
    @include center-block();
    @include padding-mobile();

    max-width: $value;

  }

}


