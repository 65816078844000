/* ==========================================================================
    #SELECTORS
========================================================================== */

// elements
/////////////////////////////
$btn : '.btn';

// compnents
/////////////////////////////
$banner : '.c-banner';
$modal : '.c-modal';
$overlay : '.c-overlay';
$wysiwyg : '.c-wysiwyg';
$loader : '.c-loader';


$components-color : '.components-color';
$partners : '.c-partners';
$text : '.c-text';
$news : '.c-news';
$hero : '.c-hero';
$hero-home : '.c-hero-home';
$features : '.c-features';

// layouts
/////////////////////////////
$wrapper : '.l-wrapper';
$header : '.l-header';
$nav : '.l-nav';
$content : '.l-content';
$footer : '.l-footer';

// pages
/////////////////////////////
$page-404 : '.error404';

// themes
/////////////////////////////
$theme-light: '.t-light';
$theme-dark: '.t-dark';
