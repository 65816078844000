/* ==========================================================================
    #HERO
    ========================================================================== */

// Variables
/////////////////////////////

#{$hero} {
  @include component-border-bottom();
  position: relative;
  text-align:center;

  color: $color-white;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size:cover;

  @include media('>tablet') {
    padding:60px 0;
  }

  @include media('<=tablet') {
    padding:30px 0;
  }

  &--has-overlay {

    &:after {
      content:"";
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index:10;
      background: rgba(#1d4672,0.8);
    }

  }

  &__container {
    position: relative;
    z-index:100;
  }

  &__logo {
    @include center-block();
    margin-bottom:30px;
  }

  &__title {
    @include gibsonSemiBold("title");
    margin-bottom:20px;

    &:last-child {
      margin-bottom: 5px;
    }

    font-size: 46px;

    @include media('<=tablet') {
      margin-bottom:10px;
      font-size:30px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

  }

  &__subtitle {
    @include gibsonSemiBold("title");
    margin-bottom:30px;
    letter-spacing: 0.075em;

    &:last-child {
      margin-bottom: 10px;
    }

    font-size: 17px;
    line-height: 1.2;
    text-transform: uppercase;

    @include media('<=tablet') {
      margin-bottom:15px;
      font-size:14px;

      &:last-child {
        margin-bottom: 5px;
      }
    }


    &:before,
    &:after{
      content:"";
      margin:0 10px;
      display: inline-block;
      width:20px;
      height:2px;

      position: relative;
      top:-5px;

      background-color: rgba(#fff,0.5);
    }

  }

  &__content {
    @include center-block();
    max-width:760px;

    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;

    @include media('<=tablet') {
      font-size:16px;
    }

    &:first-child {
      
      @include gibsonSemiBold("title");
      letter-spacing: 0.015em;
      font-size: 28px;

      @include media('<=tablet') {
        font-size:22px;
      }
    }

    p {

      &:not(last-child) {
        margin-bottom:14px;
      }

    }

  }

  .c-hero__btn {
    margin-top:40px;
    color: $color-white;
    border: 2px solid rgba($color-white, 0.5);

    &:hover {
      background-color:$color-white;
      color:$color-black;
    }
  }


}

/* ==========================================================================
    #HERO-MODIFIER
    ========================================================================== */

/* ==========================================================================
    #HERO-STYLE
    ========================================================================== */


