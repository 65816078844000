
/* ==========================================================================
    #HTML-BODY
    ========================================================================== */
body,
html {
  min-width: 320px;
  height: 100%;
}

/* ==========================================================================
    #HTML
    ========================================================================== */

html {
  overflow-y: scroll;
-ms-text-size-adjust: 100%;
-webkit-text-size-adjust: 100%;

  &.is-fixed {
    position: fixed;

    overflow: hidden;

  }

}

/* ==========================================================================
    #BODY
    ========================================================================== */

body {
  font-family: $font-text;
  font-size: 10px;
  line-height: $font-text-line-height;
  word-wrap: break-word;
  word-break: normal;

  color: #777;
  background-color:$color-white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

a {
  transition: all 0.15s;
}











