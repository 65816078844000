/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 16, 2017 */

@font-face {
    font-family: 'gibsonregular';
    src: url('#{$folder-fonts}gibson-webfont.woff2') format('woff2'),
         url('#{$folder-fonts}gibson-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gibsonsemibold';
    src: url('#{$folder-fonts}gibson-semibold-webfont.woff2') format('woff2'),
         url('#{$folder-fonts}gibson-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gibsonsemibold_italic';
    src: url('#{$folder-fonts}gibson-semibold-italic-webfont.woff2') format('woff2'),
         url('#{$folder-fonts}gibson-semibold-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gibsonbold';
    src: url('#{$folder-fonts}gibson-bold-webfont.woff2') format('woff2'),
         url('#{$folder-fonts}gibson-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gibsonbold_italic';
    src: url('#{$folder-fonts}gibson-bold-italic-webfont.woff2') format('woff2'),
         url('#{$folder-fonts}gibson-bold-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

