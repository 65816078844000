/* ==========================================================================
    #FOOTER
    ========================================================================== */

// Variables
/////////////////////////////
#{$footer} {

  .l-footer-information {
    padding: 40px 20px;
    background: linear-gradient(45deg, #444444 0%, #624d68 100%);

    @include media('>tablet') {
      padding: 40px 20px;
    }

    @include media('<=tablet') {
      padding: 40px 0;
    }

  }

  .nav-footer {
    color: rgba(#fff, 0.75);
    font-family: $GibsonRegular;

    a {
      display: block;

      &:hover {
        color: #fff;
      }
    }

    &>ul>li {
      //margin-bottom:40px;
    }

    /* level 1 */
    &>ul {

      @include media('>tablet') {
        //lost-flex-container: row;
        //flex-wrap: wrap;
      }

      &>li {
        
        @include media('>tablet') {
          lost-column: 1/3 3 60px no-flex;
        }

      }

    }

    &>ul>li {
      margin-bottom: 30px;


      &>a {
        font-size: 23px;
        margin-bottom: 20px;
      }

    }

    /* level 2 */
    .sub-menu {

      &>li {
        margin-bottom: 10px;
        
        &:last-child {
          margin-bottom: 0;
        }

      }
      
    }

    a {
      font-size: 17px;
    }

  }

  .l-footer-information {

    .row {

        @include media('>tablet') {
          lost-flex-container: row;
          flex-wrap: wrap;
        }
 
    }

  }

  .l-footer-sitemap {

    @include media('>tablet') {
      margin-top: 60px;
      max-width: 720px;
      width:100%;
    }

  }
 
  .l-footer-contact {

    margin-left: auto;

    @include media('>tablet') {
      margin-top: 60px;
    }

    .footer-logo {
      margin-bottom: 20px;
      width:178px;
      opacity:0.6;

    }

  }

  .c-contact-info {
    color: rgba(#fff, 0.8);
    font-family: $GibsonRegular;

    &__title {
      margin-bottom: 18px;
      font-size: 23px;
    }

    &__adr {
      font-size: 18px;
      line-height: 1.6;
    }

  }

  .footer-copyright {
    
    color: rgba(#fff, 0.3);
    font-family: $GibsonRegular;
    font-size: 18px;

    @include media('>tablet') {
      margin-top: 40px;
    }

    @include media('<=tablet') {
      margin-top:20px;
    }

  }

}


/* ==========================================================================
    #FOOTER-MODIFIER
    ========================================================================== */




