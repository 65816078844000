@mixin gibsonRegular() {
  font-family:$GibsonRegular;
  font-weight:normal;
}

@mixin gibsonSemiBold( $type : "text") {
  font-family:$GibsonSemiBold;
  font-weight:normal;

  @if( $type == "title" ) {
    letter-spacing: 0.04em;
  }
  @else {
    letter-spacing: 0.02em;
  }


}

@mixin gibsonBold() {
  font-family:$GibsonBold;
  font-weight:normal;
}