/* ==========================================================================
    #NEWS
    ========================================================================== */

// Variables
/////////////////////////////

#{$news} {
  @include component-border-bottom();
  text-align:center;

  @include media('>tablet') {
    padding:110px 0;
  }

  @include media('<=tablet') {
    padding:30px 0;
  }

  &__title {
    @include gibsonSemiBold("title");
    margin-bottom:60px;

    font-size: 46px;

    @include media('<=tablet') {
      margin-bottom:30px;
      font-size:28px;
    }

    @include media('<=phone') {
      font-size:20px;
    }

  }

  &__list {

    li {
      margin-bottom:40px;

    }

    &-date {
      @include gibsonSemiBold();
      display: inline-block;

      font-size: 16px;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing:1px;

      &:before,
      &:after{
        content:"";
        margin:0 10px;
        display: inline-block;
        width:20px;
        height:2px;

        position: relative;
        top:-3px;

        background-color: rgba(#fff,0.5);
      }

    }

    &-content {
      margin-top:20px;

      font-size: 20px;
      line-height: 1.4;
    }

  }

  &__title + &__list {

    @include media('<=tablet') {
      margin-top:60px;
    }

    @include media('<=phone') {
      margin-top:30px;
    }

  }

}

/* ==========================================================================
    #NEWS-MODIFIER
    ========================================================================== */

/* ==========================================================================
    #NEWS-STYLE
    ========================================================================== */

